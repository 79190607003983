import {
  FETCHED_AUTH_FAILURE,
  FETCHED_AUTH_SUCCESS,
  FETCHED_USER_FAILURE,
  FETCHED_USER_SUCCESS,
  FETCHING_AUTH,
  FETCHING_USER,
} from '../constants';
import API from '../helper/api';

export const loginUser = (data) => (dispatch) => {
  API.loginUser(data)
    .then((resp) => {
      if (resp.status === 200 && resp.data) {
        localStorage.setItem('USER_STORED', JSON.stringify(resp.data));
        return dispatch({
          type: FETCHED_AUTH_SUCCESS,
          payload: resp.data,
        });
      }

      dispatch({
        type: FETCHED_AUTH_FAILURE,
        payload: `Status code ${resp.status}`,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCHED_AUTH_FAILURE,
        payload: err.message,
      });
    });
};

export const fetchUser = (token) => (dispatch) => {
  dispatch({
    type: FETCHING_USER,
  });
  API.getUser()
    .then((resp) => {
      if (resp.status === 200) {
        return dispatch({
          type: FETCHED_USER_SUCCESS,
          payload: resp.data,
        });
      }
    })
    .catch((err) => {
      return dispatch({
        type: FETCHED_USER_FAILURE,
      });
    });
};
