import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from '../reducers';
// import { composeWithDevTools } from 'redux-devtools-extension';
export default function configureStore() {
  let middlewares = [thunk, logger];

  //   if (__DEV__) {
  //     return createStore(
  //       rootReducer,
  //       composeWithDevTools(applyMiddleware(...middlewares))
  //     );
  //   }
  return createStore(rootReducer, applyMiddleware(...middlewares));
  // if (!window.__REDUX_DEVTOOLS_EXTENSION__) {
  //   middlewares.push(logger);
  // }
}
