import axios from 'axios';

let instance = axios.create({});

instance.interceptors.request.use(async (config) => {
  const user = await JSON.parse(localStorage.getItem('USER_STORED'));

  if (user) {
    config.headers['x-access-token'] = user.accessToken;
  }

  return config;
});

export default instance;
