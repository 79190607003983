import axios from 'axios';
import API_ENDPOINTS from './api_endpoints';
import axiosInc from '../helper/axiosInterceptor';

const API = {
  loginUser: (data) => axios.post(API_ENDPOINTS.LOGIN, data),

  getUser: () => axiosInc.get(API_ENDPOINTS.USER),
  getForm: () => axios.get(API_ENDPOINTS.FORM),

  getWorkers: (pageNumber) => {
    let fullURL = `${API_ENDPOINTS.WORKERS}`;
    let body = { page: pageNumber, limit: 7 };
    return axiosInc.post(fullURL, { ...body });
  },
  getJobs: (pageNumber) => {
    let fullURL = `${API_ENDPOINTS.JOBS}`;
    let body = { page: pageNumber, limit: 7 };
    return axiosInc.post(fullURL, body);
  },
  getCompanies: (pageNumber) => {
    let fullURL = `${API_ENDPOINTS.COMPANIES}`;
    let body = { page: pageNumber, limit: 7 };
    return axiosInc.post(fullURL, body);
  },
  getUsers: (pageNumber) => {
    let fullURL = `${API_ENDPOINTS.USERS}`;
    let body = { page: pageNumber, limit: 7 };
    return axiosInc.post(fullURL, body);
  },

  getCompanyJobsById: (companyId) =>
    axios.get(API_ENDPOINTS.COMPANY_JOBS_BY_ID(companyId)),
  getCompanyRecentViews: (companyId) =>
    axios.get(API_ENDPOINTS.COMPANY_RECENT_VIEWS(companyId)),
  getWorkerById: (workerId) => axios.get(API_ENDPOINTS.WORKER_BY_ID(workerId)),
  getJobApplications: (companyId, jobId) =>
    axios.get(API_ENDPOINTS.JOB_APPLICANTS(companyId, jobId)),
  getAllMessages: () => axios.get(API_ENDPOINTS.USER_MESSAGES),
  getPopularJobs: () => axios.get(API_ENDPOINTS.POPULAR_JOBS),
  getFavoriteJobs: (workerId) =>
    axios.get(API_ENDPOINTS.FAVORITE_JOBS(workerId)),
  getWorkerJobViews: (workerId) =>
    axios.get(API_ENDPOINTS.WORKER_JOB_VIEWS(workerId)),
  postWorkerApplyForJob: ({ worker_id, job_id, company_id }) =>
    axios.post(API_ENDPOINTS.WORKER_APPLY_FOR_JOB(job_id), {
      worker_id,
      company_id,
    }),
  registerPushToken: (token) =>
    axios.post(API_ENDPOINTS.REGISTER_PUSH_TOKEN, {
      token,
    }),
  postFavoriteJob: (worker_id, job_id, company_id) =>
    axios.post(API_ENDPOINTS.FAVORITE_JOBS(worker_id), {
      job_id,
      company_id,
    }),
  deleteFavoriteJob: (worker_id, job_id) =>
    axios.delete(API_ENDPOINTS.FAVORITE_JOBS(worker_id), {
      data: {
        job_id,
      },
    }),
  getJobById: (job_id) => axios.get(API_ENDPOINTS.GET_JOB(job_id)),
  getFullFavoriteJobs: (worker_id, data) =>
    axios.post(API_ENDPOINTS.GET_FULL_FAVORITE_JOBS(worker_id), {
      data,
    }),
  getJobCategories: () => axios.get(API_ENDPOINTS.GET_JOB_CATEGORIES),
  searchJobs: (data) => axios.post(API_ENDPOINTS.SEARCH_JOBS, data),
  getCompanyById: (company_id) =>
    axios.get(API_ENDPOINTS.COMPANY_BY_ID(company_id)),
  getJobAttributes: () => axios.get(API_ENDPOINTS.GET_JOB_ATTRIBUTES),
  getWorkerJobApplications: (workerId) =>
    axios.get(API_ENDPOINTS.GET_WORKER_APPLICATIONS(workerId)),
  postJoby: (body) => axios.post(API_ENDPOINTS.POST_JOB, body),
  deleteJobById: (jobId) => axios.delete(API_ENDPOINTS.DELETE_JOB(jobId)),
  changePassword: (oldPassword, newPassword) =>
    axios.post(API_ENDPOINTS.CHANGE_PASSWORD, {
      oldPassword,
      newPassword,
    }),
  getProfileViews: (uuid) => axios.get(API_ENDPOINTS.GET_PROFILE_VIEWS(uuid)),
  addProfileView: (workerId, companyId) =>
    axios.post(API_ENDPOINTS.ADD_PROFILE_VIEW, {
      workerId,
      companyId,
    }),
  addJobField: (dbField, payload) =>
    axios.post(API_ENDPOINTS.ADD_FIELD_TYPE, {
      dbField,
      payload,
    }),
  removeJobField: (dbField, id) =>
    axios.post(API_ENDPOINTS.REMOVE_FIELD_TYPE, { dbField, id }),
  getAllLocations: () => axios.get(API_ENDPOINTS.ALL_LOCATIONS),
  addLocation: (name) =>
    axios.post(API_ENDPOINTS.ADD_LOCATION, { locationName: name }),
  removeLocation: (id) => axios.post(API_ENDPOINTS.REMOVE_LOCATION, { id }),
};

export default API;
