import {
  FETCHING_AUTH,
  FETCHED_AUTH_SUCCESS,
  FETCHED_AUTH_FAILURE,
} from '../constants';

const initialState = {
  data: null,
  isFetching: false,
  fetched: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_AUTH:
      return { data: null, isFetching: true, fetched: false, error: null };
    case FETCHED_AUTH_SUCCESS:
      return {
        data: action.payload,
        isFetching: false,
        fetched: true,
        error: null,
      };
    case FETCHED_AUTH_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
