import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { fetchUser, loginUser } from '../actions';
import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Login = lazy(() => import('./user-pages/Login'));

class AppRoutes extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount = () => {
    if (!this.props.auth.accessToken) {
      let user = JSON.parse(localStorage.getItem('USER_STORED'));
      if (user) this.props.fetchUser();
    }
  };

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        {this.props.auth.data || this.props.user.data ? (
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />

            {/* <Route path="/basic-ui/buttons" component={Buttons} />
            <Route path="/basic-ui/dropdowns" component={Dropdowns} />
            <Route path="/basic-ui/typography" component={Typography} />

            <Route
              path="/form-Elements/basic-elements"
              component={BasicElements}
            />

            <Route path="/tables/basic-table" component={BasicTable} />

            <Route path="/icons/mdi" component={Mdi} />

            <Route path="/charts/chart-js" component={ChartJs} />

            <Route path="/user-pages/register-1" component={Register1} />

            <Route path="/error-pages/error-404" component={Error404} />
            <Route path="/error-pages/error-500" component={Error500} /> */}

            <Redirect to="/dashboard" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/login" component={Login} />

            <Redirect to="/login" />
          </Switch>
        )}
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    user: state.userReducer,
  };
};

export default connect(mapStateToProps, { loginUser, fetchUser })(AppRoutes);
