export const FETCHING_AUTH = 'FETCHING_AUTH';
export const FETCHED_AUTH_SUCCESS = 'FETCHED_AUTH_SUCCESS';
export const FETCHED_AUTH_FAILURE = 'FETCHED_AUTH_FAILURE';
export const DESTROY_SESSION = 'DESTROY_SESSION';

export const FETCHING_USER = 'FETCHING_USER';
export const FETCHED_USER_SUCCESS = 'FETCHED_USER_SUCCESS';
export const FETCHED_USER_FAILURE = 'FETCHED_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';

//LOADER
export const SET_LOADER_PERCENTAGE = 'SET_LOADER_PERCENTAGE';
export const RESET_LOADER_PERCENTAGE = 'RESET_LOADER_PERCENTAGE';
export const STORE_LOADER_PERCENTAGE = 'STORE_LOADER_PERCENTAGE';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const FETCHING_PRODUCTS = 'FETCHING_PRODUCTS';
export const FETCHED_PRODUCTS_SUCCESS = 'FETCHED_PRODUCTS_SUCCESS';
export const FETCHED_PRODUCTS_FAILURE = 'FETCHED_PRODUCTS_FAILURE';
export const OPEN_PRODUCT = 'OPEN_PRODUCT';

export const FETCHING_WISHLISTS = 'FETCHING_WISHLISTS';
export const FETCHED_WISHLISTS_SUCCESS = 'FETCHED_WISHLISTS_SUCCESS';
export const FETCHED_WISHLISTS_FAILURE = 'FETCHED_WISHLISTS_FAILURE';

export const SET_SCROLL_ACTIVE = 'SET_SCROLL_ACTIVE';
export const SHOW_ALERT_NOTIFY = 'SHOW_ALERT_NOTIFY';
export const HIDE_ALERT_NOTIFY = 'HIDE_ALERT_NOTIFY';

export const FETCHING_JOBS = 'FETCHING_JOBS';
export const FETCHED_JOBS_SUCCESS = 'FETCHED_JOBS_SUCCESS';
export const FETCHED_JOBS_FAILURE = 'FETCHED_JOBS_FAILURE';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';

export const FETCHING_FAVORITES = 'FETCHING_FAVORITES';
export const FETCHED_FAVORITES_SUCCESS = 'FETCHED_FAVORITES_SUCCESS';
export const FETCHED_FAVORITES_FAILURE = 'FETCHED_FAVORITES_FAILURE';

export const FETCHING_NOTIFICATIONS = 'FETCHING_NOTIFICATIONS';
export const FETCHED_NOTIFICATIONS_SUCCESS = 'FETCHED_NOTIFICATIONS_SUCCESS';
export const FETCHED_NOTIFICATIONS_FAILURE = 'FETCHED_NOTIFICATIONS_FAILURE';

export const FETCHING_MESSAGES = 'FETCHING_MESSAGES';
export const FETCHED_MESSAGES_SUCCESS = 'FETCHED_MESSAGES_SUCCESS';
export const FETCHED_MESSAGES_FAILURE = 'FETCHED_MESSAGES_FAILURE';
export const APPEND_SOCKET_MESSAGE = 'APPEND_SOCKET_MESSAGE';

export const FETCHING_COMPANY_RECENT_VIEWS = 'FETCHING_COMPANY_RECENT_VIEWS';
export const FETCHED_COMPANY_RECENT_VIEWS_SUCCESS =
  'FETCHED_COMPANY_RECENT_VIEWS_SUCCESS';
export const FETCHED_COMPANY_RECENT_VIEWS_FAILURE =
  'FETCHED_COMPANY_RECENT_VIEWS_FAILURE';

export const FETCHING_APPLICANTS = 'FETCHING_APPLICANTS';
export const FETCHED_APPLICANTS_SUCCESS = 'FETCHED_APPLICANTS_SUCCESS';
export const FETCHED_APPLICANTS_FAILURE = 'FETCHED_APPLICANTS_FAILURE';

export const FETCHING_POPULAR_JOBS = 'FETCHING_POPULAR_JOBS';
export const FETCHED_POPULAR_JOBS_SUCCESS = 'FETCHED_POPULAR_JOBS_SUCCESS';
export const FETCHED_POPULAR_JOBS_FAILURE = 'FETCHED_POPULAR_JOBS_FAILURE';

export const FETCHING_FAVORITE_JOBS = 'FETCHING_FAVORITE_JOBS';
export const FETCHED_FAVORITE_JOBS_SUCCESS = 'FETCHED_FAVORITE_JOBS_SUCCESS';
export const FETCHED_FAVORITE_JOBS_FAILURE = 'FETCHED_FAVORITE_JOBS_FAILURE';

export const FETCHING_PROFILE_VIEWS = 'FETCHING_PROFILE_VIEWS';
export const FETCHED_PROFILE_VIEWS_SUCCESS = 'FETCHED_PROFILE_VIEWS_SUCCESS';
export const FETCHED_PROFILE_VIEWS_FAILURE = 'FETCHED_PROFILE_VIEWS_FAILURE';

export const WORKER_APPLYING_FOR_JOB = 'WORKER_APPLYING_FOR_JOB';
export const WORKER_APPLYING_FOR_JOB_SUCCESS =
  'WORKER_APPLYING_FOR_JOB_SUCCESS';
export const WORKER_APPLYING_FOR_JOB_FAILURE =
  'WORKER_APPLYING_FOR_JOB_FAILURE';

export const WORKER_ADDING_FAVORITE_JOB = 'WORKER_ADDING_FAVORITE_JOB';
export const WORKER_ADD_FAVORITE_JOB_SUCCESS =
  'WORKER_ADD_FAVORITE_JOB_SUCCESS';
export const WORKER_ADD_FAVORITE_JOB_FAILURE =
  'WORKER_ADD_FAVORITE_JOB_FAILURE';

export const WORKER_DELETING_FAVORITE_JOB = 'WORKER_DELETING_FAVORITE_JOB';
export const WORKER_DELETE_FAVORITE_JOB_SUCCESS =
  'WORKER_DELETE_FAVORITE_JOB_SUCCESS';
export const WORKER_DELETE_FAVORITE_JOB_FAILURE =
  'WORKER_DELETE_FAVORITE_JOB_FAILURE';

export const WORKER_FETCHING_JOB = 'WORKER_FETCHING_JOB';
export const WORKER_FETCH_JOB_SUCCESS = 'WORKER_FETCH_JOB_SUCCESS';
export const WORKER_FETCH_JOB_FAILURE = 'WORKER_FETCH_JOB_FAILURE';

export const WORKER_FETCHING_FULL_FAVORITE_JOBS =
  'WORKER_FETCHING_FULL_FAVORITE_JOBS';
export const WORKER_FETCH_FULL_FAVORITE_JOB_SUCCESS =
  'WORKER_FETCH_FULL_FAVORITE_JOB_SUCCESS';
export const WORKER_FETCH_FULL_FAVORITE_JOB_FAILURE =
  'WORKER_FETCH_FULL_FAVORITE_JOB_FAILURE';

export const SETTING_FILTERS_JOB_CATEGORY = 'SETTING_FILTERS_JOB_CATEGORY';
export const SETTING_FILTERS_JOB_TYPE = 'SETTING_FILTERS_JOB_TYPE';
export const SETTING_FILTERS_JOB_SALARY = 'SETTING_FILTERS_JOB_SALARY';
export const SET_JOB_FILTERS = 'SET_JOB_FILTERS';

export const FETCHING_JOB_ATTRIBUTES = 'FETCHING_JOB_ATTRIBUTES';
export const FETCHED_JOB_ATTRIBUTES_SUCCESS = 'FETCHED_JOB_ATTRIBUTES_SUCCESS';
export const FETCHED_JOB_ATTRIBUTES_FAILURE = 'FETCHED_JOB_ATTRIBUTES_FAILURE';

export const COMPANY_POSTING_JOB_SUCCESS = 'COMPANY_POSTING_JOB_SUCCESS';
export const COMPANY_POSTING_JOB_FAILURE = 'COMPANY_POSTING_JOB_FAILURE';
