import { combineReducers } from 'redux';
import { DESTROY_SESSION } from '../constants';
import authReducer from './reducer_auth';
import userReducer from './reducer_user';

const reducers = {
  authReducer,
  userReducer,
};

const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) state = {};

  return appReducer(state, action);
};

export default rootReducer;
