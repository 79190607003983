const SERVER_BASE_URL = 'https://capo-backend.server.wonderit.io/api';

const API_ENDPOINTS = {
  REGISTER: `${SERVER_BASE_URL}/signup`,
  LOGIN: `${SERVER_BASE_URL}/signin/admin`,
  USER: `${SERVER_BASE_URL}/user`,
  FORM: `${SERVER_BASE_URL}/api/form`,

  WORKERS: `${SERVER_BASE_URL}/analytics/all-workers`,
  JOBS: `${SERVER_BASE_URL}/analytics/all-jobs`,
  COMPANIES: `${SERVER_BASE_URL}/analytics/all-companies`,
  USERS: `${SERVER_BASE_URL}/analytics/all-users`,

  PREDICT: `${SERVER_BASE_URL}/api/image_upload`,
  PRODUCTS: `${SERVER_BASE_URL}/api/products`,
  COMPANY_JOBS_BY_ID: (companyId) =>
    `${SERVER_BASE_URL}/company/${companyId}/jobs`,
  COMPANY_RECENT_VIEWS: (companyId) =>
    `${SERVER_BASE_URL}/company/${companyId}/views`,
  COMPANY_BY_ID: (companyId) => `${SERVER_BASE_URL}/company/${companyId}`,
  WORKER_BY_ID: (workerId) => `${SERVER_BASE_URL}/worker/${workerId}`,
  JOB_APPLICANTS: (companyId, jobId) =>
    `${SERVER_BASE_URL}/company/${companyId}/${jobId}/applicants`,
  USER_MESSAGES: `${SERVER_BASE_URL}/messages`,
  POPULAR_JOBS: `${SERVER_BASE_URL}/jobs/popular`,
  FAVORITE_JOBS: (workerId) =>
    `${SERVER_BASE_URL}/worker/${workerId}/favorite-jobs`,
  WORKER_JOB_VIEWS: (workerId) =>
    `${SERVER_BASE_URL}/worker/${workerId}/job-views`,
  WORKER_APPLY_FOR_JOB: (job_id) => `${SERVER_BASE_URL}/jobs/apply/${job_id}`,

  REGISTER_PUSH_TOKEN: `${SERVER_BASE_URL}/notifications/add`,
  GET_JOB: (job_id) => `${SERVER_BASE_URL}/jobs/${job_id}`,
  GET_FULL_FAVORITE_JOBS: (workerId) =>
    `${SERVER_BASE_URL}/worker/${workerId}/full-favorite-jobs`,
  GET_JOB_CATEGORIES: `${SERVER_BASE_URL}/jobs/categories`,
  SEARCH_JOBS: `${SERVER_BASE_URL}/jobs/search`,
  GET_JOB_ATTRIBUTES: `${SERVER_BASE_URL}/static/attributes`,
  GET_WORKER_APPLICATIONS: (workerId) =>
    `${SERVER_BASE_URL}/worker/${workerId}/applications`,
  POST_JOB: `${SERVER_BASE_URL}/jobs`,
  DELETE_JOB: (job_id) => `${SERVER_BASE_URL}/jobs/${job_id}`,
  CHANGE_PASSWORD: `${SERVER_BASE_URL}/user/password`,
  GET_PROFILE_VIEWS: (uuid) => `${SERVER_BASE_URL}/views/profile/${uuid}`,
  ADD_PROFILE_VIEW: `${SERVER_BASE_URL}/views/profile`,
  ADD_FIELD_TYPE: `${SERVER_BASE_URL}/static/add-category`,
  REMOVE_FIELD_TYPE: `${SERVER_BASE_URL}/static/remove-category`,
  ALL_LOCATIONS: `${SERVER_BASE_URL}/static/locations`,
  ADD_LOCATION: `${SERVER_BASE_URL}/static/add-location`,
  REMOVE_LOCATION: `${SERVER_BASE_URL}/static/remove-location`,
};

export default API_ENDPOINTS;
